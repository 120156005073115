<div class="swap-input uni-input">

  <div class="w-full">
    <div style="height: 40px;" class="flex justify-between">

      <div class="text-gray-300">
        <label for="{{label}}Input">{{label}}</label>
        <div style="height: 16px;">
          <span class="float-left text-xs h-4" *ngIf="inputUsdValue">(<i>approx ${{inputUsdValue | number:"1.0-0"}}</i>)</span>
        </div>
      </div>

      <label class="text-gray-300" *ngIf="selectedAsset && balance">
        Balance: {{balance | number:"1.0-8"}} <br />
        <div style="height: 16px;">
          <span class="float-right text-xs h-4" *ngIf="usdValue">(<i>approx ${{usdValue | number:"1.0-0"}}</i>)</span>
        </div>
      </label>
    </div>

    <div class="flex justify-between items-center">

      <div *ngIf="loading" class="flex items-center" style="height: 53px">
        <mat-progress-spinner diameter="16" color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>

      <div *ngIf="!loading && error" class="flex items-center" style="height: 53px">
        <mat-icon style="font-size: 1rem; height: 18px;">warning</mat-icon>
        <span style="font-size: 0.8rem">Error fetching pool</span>
      </div>

      <input
        *ngIf="!loading && !error"
        class="focus:outline-none focus:ring-0 text-2xl"
        [ngModel]="assetUnit"
        (ngModelChange)="updateAssetUnits($event)"
        id="{{label}}Input"
        name="fromUnit"
        placeholder="0.0"
        type="number"
        [disabled]="disableInput" />

      <div class="flex items-center">

        <button class="focus:outline-none" *ngIf="!selectedAsset" style="min-width: 160px;" mat-flat-button (click)="launchMarketsModal()">
          Select token
          <mat-icon>expand_more</mat-icon>
        </button>

        <button (click)="setMax()" *ngIf="balance && balance > 0 && balance != assetUnit && !hideMax" class="mr-1 rounded py-1 px-3 text-blue-400 bg-blue-700 border border-blue-700 bg-opacity-25 border-opacity-50 hover:border-blue-600">
          MAX
        </button>

        <button *ngIf="selectedAsset" class="launch-markets-btn focus:outline-none" (click)="launchMarketsModal()" [disabled]="disabledMarketSelect">
          <div class="flex items-center asset-selector">
            <app-icon-ticker [iconPath]="selectedAsset.iconPath" [ticker]="selectedAsset.ticker"></app-icon-ticker>
            <mat-icon *ngIf="!disabledMarketSelect">expand_more</mat-icon>
          </div>
        </button>

      </div>

    </div>
  </div>

</div>
