import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { retry, share, switchMap } from 'rxjs/operators';
import { LuumService, MayanodeQueue } from './luum.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkQueueService {
  networkQueue$: Observable<MayanodeQueue>;

  constructor(private luumService: LuumService) {
    this.networkQueue$ = timer(0, 60000).pipe(
      switchMap(() => this.luumService.getQueue()),
      retry(),
      share()
    );
  }
}
