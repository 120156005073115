import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { decryptFromKeystore } from '@xchainjs/xchain-crypto';
import { User } from '../_classes/user';
import { Client as binanceClient } from '@xchainjs/xchain-binance';
import { Client as bitcoinClient } from '@xchainjs/xchain-bitcoin';
import { Client as mayachainClient } from '@mayaprotocol/xchain-mayachain';
import { Client as thorchainClient } from '@xchainjs/xchain-thorchain';
import { Client as ethereumClient } from '@xchainjs/xchain-ethereum';
import { Client as litecoinClient } from '@xchainjs/xchain-litecoin';
import { Client as bitcoinCashClient } from '@xchainjs/xchain-bitcoincash';
import { Network } from '@xchainjs/xchain-client';

@Injectable({
  providedIn: 'root',
})
export class KeystoreService {
  constructor() {}

  async unlockKeystore(keystore, password: string): Promise<User> {
    const phrase = await decryptFromKeystore(keystore, password);
    const network = environment.network as Network;
    const userBinanceClient = new binanceClient({ network, phrase });
    const userBtcClient = new bitcoinClient({
      network,
      phrase,
      sochainUrl: 'https://sochain.com/api/v2',
    });
    const userMayachainClient = new mayachainClient({
      network,
      phrase,
      clientUrl: {
        [Network.Mainnet]: {
          rpc: 'https://rpc.mayachain.io',
          node: 'https://rpc.mayachain.io',
        },
        [Network.Testnet]: {
          rpc: 'https://rpc.mayachain.io',
          node: 'https://rpc.mayachain.io',
        },
        [Network.Stagenet]: {
          rpc: 'https://stagenet.tendermint.mayachain.info',
          node: 'https://stagenet.mayanode.mayachain.info',
        },
      },
      chainIds: {
        mainnet: 'mayachain-mainnet-v1',
        stagenet: 'mayachain-stagenet-v1',
        testnet: 'mayachain-stagenet-v1',
      },
    });
    const mayaAddress = await userMayachainClient.getAddress();
    const userThorchainClient = new thorchainClient({
      network,
      phrase,
      chainIds: {
        mainnet: 'thorchain-mainnet-v2',
        stagenet: 'thorchain-stagenet-v2',
        testnet: 'thorchain-stagenet-v1',
      },
    });
    const thorAddress = await userThorchainClient.getAddress();
    const userEthereumClient = new ethereumClient({
      network,
      phrase,
      etherscanApiKey: environment.etherscanKey,
      infuraCreds: { projectId: environment.infuraProjectId },
    });
    const userLtcClient = new litecoinClient({ network, phrase });
    const userbchClient = new bitcoinCashClient({ network, phrase });

    console.log(mayaAddress)
    return new User({
      type: 'keystore',
      wallet: mayaAddress,
      keystore,
      clients: {
        binance: userBinanceClient,
        bitcoin: userBtcClient,
        bitcoinCash: userbchClient,
        mayachain: userMayachainClient,
        thorchain: userThorchainClient,
        ethereum: userEthereumClient,
        litecoin: userLtcClient,
      },
    });
  }
}
