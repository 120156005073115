
// prettier-ignore
import { Network } from '@xchainjs/xchain-client';

export const environment: {
  production: boolean;
  network: Network;
  etherscanKey: string;
  infuraProjectId: string;
  appLocked: boolean;
} = {
   production: true,
   network: Network.Stagenet,
   etherscanKey: 'MRUDKZ8XT5MD4YN5K2MSNBEZ4CXH2R3C4A',
   infuraProjectId: '99d9b5c44af94b4c96344b6e351ce639',
   appLocked: false
};
