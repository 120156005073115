import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetAndBalance } from 'src/app/_classes/asset-and-balance';
import { NativeCacaoPromptModalComponent } from './native-cacao-prompt-modal/native-cacao-prompt-modal.component';

@Component({
  selector: 'app-native-cacao-prompt',
  templateUrl: './native-cacao-prompt.component.html',
  styleUrls: ['./native-cacao-prompt.component.scss'],
})
export class NativeCacaoPromptComponent {
  @Input() nonNativeCacaoAssets: AssetAndBalance[];

  constructor(private dialog: MatDialog) {}

  launchModal() {
    const dialogRef = this.dialog.open(NativeCacaoPromptModalComponent, {
      width: '50vw',
      maxWidth: '420px',
      minWidth: '260px',
      data: {
        assets: this.nonNativeCacaoAssets,
      },
    });
  }
}
