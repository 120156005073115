<div class="flex justify-between items-center pb-4 px-4" >
  <div class="font-medium">
    Token Name
  </div>
  <span class="mr-2 text-gray-400 text-xs">BALANCE</span>
</div>

<div class="market-list-container relative w-full h-80 overflow-y-auto border-t border-gray-700">

  <div *ngIf="loading" class="flex items-center justify-center w-full h-full">
    <mat-progress-spinner diameter="16" color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>

  <ul *ngIf="!loading && assetListItems" class="market-list">

    <li *ngFor="let item of assetListItems"
      class="flex items-center py-1 px-4 cursor-pointer hover:bg-white hover:bg-opacity-10 h-16"
      [class.text-gray-600]="item.asset.symbol == disabledAssetSymbol"
      (click)="selectAsset.emit(item.asset)"
      [class.disabled]="item.asset.symbol == disabledAssetSymbol">

      <div class="asset-icon-container w-8 flex-shrink-0">
        <img *ngIf="item.asset.iconPath" class="asset-icon" [src]="item.asset.iconPath" appImgFallback />
        <mat-icon *ngIf="!item.asset.iconPath">help_outline</mat-icon>
      </div>

      <div class="flex flex-col flex-grow">

        <div class="flex justify-between">
          <span class="font-medium">{{item.asset.ticker}}</span>
          <div *ngIf="item.balance" class="flex flex-col justify-end text-right">
            <span class="text-sm font-normal">{{item.balance.amount().toNumber()}}</span>
          </div>
          <div *ngIf="!item.balance" class="flex flex-col justify-end text-right">
            <span class="text-sm font-normal">0</span>
          </div>
        </div>

        <span *ngIf="item.asset.chain === 'ETH' && item.asset.ticker !== 'ETH'" class="text-xs text-gray-400">{{item.asset.symbol.split('-')[1]}}</span>
        <span class="text-xs text-gray-400">{{item.asset.chain}}</span>
      </div>

    </li>
    <li *ngIf="displayAddTokenButton" class="flex justify-center items-center py-4">
      <button mat-flat-button color="accent" (click)="addToken.emit()">Add Token</button>
    </li>
  </ul>

</div>
