
<div>

  <div class="p-4 flex flex-col">

    <div class="flex justify-between items-center mb-2">
      <h1 mat-dialog-title>Select a token</h1>
      <!-- close dialog -->
      <div mat-dialog-actions>
        <button mat-icon-button aria-label="Close Markets Dialog" (click)="closeDialog()" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div class="uni-input mt-4">
      <input class="p-0" [(ngModel)]="searchTerm" name="keystore-password" placeholder="Search Token Symbol" />
    </div>

  </div>
  <div>
    <div mat-dialog-content>

      <app-assets-list
        [loading]="loading"
        [assetListItems]="filteredMarketListItems"
        [disabledAssetSymbol]="data.disabledAssetSymbol"
        (selectAsset)="selectItem($event)">
      </app-assets-list>

    </div>

  </div>

</div>

