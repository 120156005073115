<div (click)="launchModal()" class="mb-8 cursor-pointer min-h-0 border-opacity-75 border rounded-lg text-yellow-500 border-yellow-500 px-6 py-4" *ngIf="nonNativeCacaoAssets && nonNativeCacaoAssets.length > 0">
  <div class="flex items-center">
    <mat-icon class="mr-4">warning</mat-icon>
    <small>
      <span *ngFor="let asset of nonNativeCacaoAssets; let i = index">
        {{asset.asset.chain}}.CACAO<span *ngIf="i == 0 && nonNativeCacaoAssets.length > 1">,</span>
      </span>
      <span> detected. <br /> Upgrade to Native CACAO.</span>
    </small>
  </div>
</div>
