<div class="container">

  <app-modal-section-header sectionTitle="Error Connecting" [displayBackBtn]="true" (back)="back.emit()"></app-modal-section-header>

  <div class="dialog-content">
    <div mat-dialog-content>

      <div class="flex flex-col items-center justify-center">
        <mat-icon>report</mat-icon>
        <p>There was an error connecting</p>
      </div>

    </div>

  </div>

</div>
