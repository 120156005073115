<div>

  <div *ngIf="!connectionView">

    <div class="flex justify-between w-full p-4 items-center">
      <h1 mat-dialog-title>Connect to a wallet</h1>
      <div mat-dialog-actions>
        <button mat-icon-button aria-label="Close Connect Wallet Dialog" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div>
      <div mat-dialog-content>

        <!-- <button class="connect-option focus:outline-none flex items-center w-full h-14 hover:bg-gray-600 hover:bg-opacity-25" (click)="connectXDEFI()">
          <img width="48px" src="/assets/images/XDEFI-Brandmark-White.png" />
          <span class="ml-1" *ngIf="isXDEFIConnected">Connect with XDEFI</span>
          <span class="ml-1" *ngIf="!isXDEFIConnected">Install XDEFI</span>
        </button>

        <button class="connect-option focus:outline-none pl-3 flex items-center w-full h-14 hover:bg-gray-600 hover:bg-opacity-25" (click)="connectMetaMask()">
          <img class="connect-option__logo" src="https://raw.githubusercontent.com/MetaMask/metamask-extension/develop/app/images/icon-64.png" />
          <span class="ml-4">MetaMask</span>
        </button> -->

        <button class="connect-option focus:outline-none pl-3 flex items-center w-full h-14 hover:bg-gray-600 hover:bg-opacity-25" (click)="createKeystore()">
          <mat-icon class="connect-option__logo">add</mat-icon>
          <span class="ml-4">Create Keystore</span>
        </button>

        <button class="connect-option focus:outline-none pl-3 flex items-center w-full h-14 hover:bg-gray-600 hover:bg-opacity-25" (click)="connectKeystore()">
          <mat-icon class="connect-option__logo">vpn_key</mat-icon>
          <span class="ml-4">Connect Keystore</span>
        </button>

      </div>

    </div>
  </div>

  <!-- KEYSTORE CREATE -->
  <app-keystore-create *ngIf="connectionView == 'KEYSTORE_CREATE'" (back)="clearConnectionMethod()" (keystoreCreated)="storePhrasePrompt($event)" (closeModal)="close()"></app-keystore-create>

  <!-- KEYSTORE CREATE - write down phrase prompt -->
  <app-keystore-create-store-phrase *ngIf="connectionView == 'KEYSTORE_WRITE_PHRASE'" [phrase]="phrase" (closeModal)="close()"></app-keystore-create-store-phrase>

  <!-- KEYSTORE CONNECT -->
  <app-keystore-connect *ngIf="connectionView == 'KEYSTORE_CONNECT'" (back)="clearConnectionMethod()" (closeModal)="close()"></app-keystore-connect>

  <!-- XDEFI CONNECT -->
  <app-xdefi-connect *ngIf="connectionView == 'XDEFI'" (back)="clearConnectionMethod()" (closeModal)="close()"></app-xdefi-connect>

</div>

