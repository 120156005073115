<div class="success">
  <div class="icon-container icon-container--sent">
    <mat-icon>arrow_circle_up</mat-icon>
  </div>

  <div class="flex justify-center flex-col items-center p-4">

    <h1 mat-dialog-title class="text-2xl">{{label}}</h1>

    <div *ngIf="!externalTx">
      <a target="_blank" [href]="mayachainExplorerUrl + '/' + hash">View on MAYA Explorer</a>
    </div>

    <div *ngIf="externalTx">
      <a target="_blank" *ngIf="hash && chain === 'BNB'" [href]="binanceExplorerUrl + '/' + hash">View on BNC Explorer</a>
      <a target="_blank" *ngIf="hash && chain === 'BTC'" [href]="bitcoinExplorerUrl + '/' + hash">View on BTC Explorer</a>
      <a target="_blank" *ngIf="hash && chain === 'ETH'" [href]="ethereumExplorerUrl + '/' + hash">View on ETH Explorer</a>
      <a target="_blank" *ngIf="hash && chain === 'LTC'" [href]="litecoinExplorerUrl + '/' + hash">View on LTC Explorer</a>
    </div>

    <button (click)="closeDialog.emit()" mat-flat-button color="primary" class="main-action-btn w-full">Close</button>

  </div>

</div>
