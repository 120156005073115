import { CoinIconsFromTrustWallet } from 'src/app/_const/icon-list';
import { assetFromString, assetToString, Chain } from '@xchainjs/xchain-util';
import { ethers } from 'ethers';

export class Asset {
  chain: Chain;
  symbol: string;
  ticker: string;
  synth: boolean;
  iconPath: string;

  constructor(poolName: string) {
    const { chain, symbol, ticker, synth } = assetFromString(poolName);
    this.chain = chain;
    this.symbol = symbol;
    this.ticker = ticker;
    this.synth = synth;

    const trustWalletMatch = CoinIconsFromTrustWallet[this.ticker];

    if (trustWalletMatch && chain !== 'MAYA') {
      this.iconPath = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/assets/${trustWalletMatch}/logo.png`;
    } else {
      // Override token icons when not found in trustwallet

      switch (chain) {
        case 'BTC':
          this.iconPath =
            'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/assets/BTCB-1DE/logo.png';
          break;

        case 'LTC':
          this.iconPath =
            'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/litecoin/info/logo.png';
          break;

        case 'BNB':
          if (ticker === 'BNB') {
            this.iconPath =
              'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/info/logo.png';
          }
          break;

        case 'ETH':
          if (this.symbol !== 'ETH') {
            // for ETH tokens
            //this.iconPath = this._setEthIconPath(symbol, ticker);
          }
          break;

        case 'THOR':
          this.iconPath = 'assets/images/token-icons/cacao-logo.png';
          break;

        case 'MAYA':
          this.iconPath = 'assets/images/token-icons/cacao-logo.png';
          break;

        case 'BCH':
          this.iconPath =
            'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/bitcoincash/info/logo.png';
          break;

        default:
          break;
      }
    }
  }

  //private _setEthIconPath(assetSymbol: string, assetTicker: string): string {
  //  const assetAddress = assetSymbol.slice(assetTicker.length + 1);
  //  const strip0x = assetAddress.substring(2);
  //  const checkSummedAddress = ethers.utils.getAddress(strip0x);
  //  return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${checkSummedAddress}/logo.png`;
  //}
}

export const checkSummedAsset = (poolName: string): Asset => {
  const asset = new Asset(poolName);
  const assetAddress = asset.symbol.slice(asset.ticker.length + 1);
  const strip0x =
    assetAddress.substring(0, 2).toUpperCase() === '0X'
      ? assetAddress.substring(2)
      : assetAddress;
  const checkSummedAddress = ethers.utils.getAddress(strip0x);
  return {
    chain: asset.chain,
    ticker: asset.ticker,
    symbol: `${asset.ticker}-${checkSummedAddress}`,
    synth: asset.synth,
    iconPath: '',
  };
};

export const isNonNativeCacaoToken = (asset: {
  chain: Chain;
  ticker: string;
  symbol: string;
}): boolean => {
  const cacaoTokens = [
    'BNB.CACAO-B1A', // chaosnet
    'BNB.CACAO-67C', // testnet
    'ETH.CACAO-0XD601C6A3A36721320573885A8D8420746DA3D7A0', // testnet
    'ETH.CACAO-0X3155BA85D5F96B2D030A4966AF206230E46849CB', // chaosnet
  ];

  return cacaoTokens.includes(`${asset.chain}.${asset.symbol}`.toUpperCase());
};

export const getChainAsset = (chain: Chain): Asset => {
  switch (chain) {
    case 'BTC':
      return new Asset('BTC.BTC');

    case 'LTC':
      return new Asset('LTC.LTC');

    case 'BCH':
      return new Asset('BCH.BCH');

    case 'ETH':
      return new Asset('ETH.ETH');

    case 'BNB':
      return new Asset('BNB.BNB');

    case 'THOR':
      return new Asset('THOR.RUNE');

    case 'MAYA':
      return new Asset('MAYA.CACAO');

    default:
      return null;
  }
};

export const assetIsChainAsset = (asset: Asset): boolean => {
  return assetToString(getChainAsset(asset.chain)) === assetToString(asset);
};
