<div class="container">

  <app-modal-section-header sectionTitle="Warning"></app-modal-section-header>

  <div class="dialog-content">
    <div mat-dialog-content>
      <p>This platform and its token are in Stagenet. This means Cacao here is for testing purposes, although you can swap it for real crypto assets. Please do not swap large amounts of external assets. The UI is under constant development and maintenance, refer to transaction memos link for sending transactions directly if you encounter any issues with the front end. If you want some test Cacao to play around with, please send a message to the Maya team through Twitter and we will send you some.</p>
    </div>
  </div>

</div>
