

<div class="flex justify-between items-center float-left w-full pt-4 px-4 mb-4">
  <button (click)="back.emit()" mat-icon-button>
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h1 class="flex items-center text-white text-lg font-medium m-0">
    <div class="asset-icon-container">
      <img *ngIf="iconPath" class="asset-icon" [src]="iconPath" />
    </div>
    {{chain}} Wallet
  </h1>
  <div style="width: 40px">
    <button mat-icon-button (click)="refreshBalances()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div>

<div class="p-4">
  <div *ngIf="address && chain" class="uni-input user-address">
    <label class="flex items-center">
      {{chain}} Address

      <a class="explorer-link" [href]="explorerPath" target="_blank">
        <mat-icon>open_in_new</mat-icon>
      </a>
    </label>

    <div class="flex w-full">
      <textarea [value]="address" name="address" [disabled]="true"></textarea>
      <button matSuffix mat-icon-button aria-label="Copy To Clipboard" (click)="copyToClipboard(address)">
        <mat-icon matSuffix (click)="copyToClipboard(address)">content_copy</mat-icon>
      </button>
    </div>

    <app-testnet-warning [chain]="chain"></app-testnet-warning>

  </div>
</div>

<div *ngIf="!error" mat-dialog-content>
  <app-assets-list
    [assetListItems]="assets"
    [loading]="loading"
    [disabledAssetSymbol]="''"
    [displayAddTokenButton]="chain === 'ETH'"
    (selectAsset)="selectAsset($event)"
    (addToken)="navigateToAddToken.emit()">
  </app-assets-list>
</div>

<div *ngIf="error" class="p-8">
  <small>{{error}}</small>
  <br />
  <a *ngIf="explorerPath" class="explorer-link text-green-400" [href]="explorerPath" target="_blank">
    <small>Check funds safe <mat-icon>open_in_new</mat-icon></small>
  </a>
</div>
