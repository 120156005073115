import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NativeCacaoPromptComponent } from './native-cacao-prompt.component';
import { MatIconModule } from '@angular/material/icon';
import { NativeCacaoPromptModalComponent } from './native-cacao-prompt-modal/native-cacao-prompt-modal.component';
import { AssetsListModule } from '../assets-list/assets-list.module';
import { UpgradeCacaoModule } from '../upgrade-cacao/upgrade-cacao.module';
import { UpgradeCacaoConfirmModule } from '../upgrade-cacao-confirm/upgrade-cacao-confirm.module';
import { TransactionSuccessModalModule } from '../transaction-success-modal/transaction-success-modal.module';
import { ModalSectionHeaderModule } from '../modal-section-header/modal-section-header.module';

@NgModule({
  declarations: [NativeCacaoPromptComponent, NativeCacaoPromptModalComponent],
  imports: [
    CommonModule,
    MatIconModule,
    AssetsListModule,
    UpgradeCacaoModule,
    UpgradeCacaoConfirmModule,
    TransactionSuccessModalModule,
    ModalSectionHeaderModule,
  ],
  exports: [NativeCacaoPromptComponent],
})
export class NativeCacaoPromptModule {}
