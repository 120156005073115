<div class="container">

  <app-modal-section-header sectionTitle="Create Keystore" [displayBackBtn]="true" (back)="back.emit()"></app-modal-section-header>

  <div class="dialog-content">
    <div mat-dialog-content>

      <div class="uni-input focus-within:border-white border-transparent border">
        <label>Password</label>
        <input [(ngModel)]="password" name="keystore-password" placeholder="Please Create Password" type="password" class="focus:outline-none focus:ring-0" />
      </div>

      <div class="uni-input focus-within:border-white border-transparent border">
        <label>Confirm Password</label>
        <input [(ngModel)]="confirmPassword" name="confirm-password" placeholder="Please confirm password" type="password" class="focus:outline-none focus:ring-0" (keyup.enter)="createKeystore()" />
      </div>

    </div>

    <div class="mb-4">
      <small><i>*This will create and download a keystore to your computer.</i></small>
    </div>

    <div class="flex justify-between items-center" style="height: 38px">
      <div>
        <span *ngIf="error" style="font-size: 0.8rem; color: #2172e5">Error Creating Keystore</span>
      </div>
      <div>
        <button *ngIf="!loading" mat-flat-button color="primary" (click)="createKeystore()" [disabled]="!phrase || !password || password != confirmPassword">
          <span>Create Keystore</span>
        </button>
        <mat-progress-spinner mode="indeterminate" diameter="30" *ngIf="loading"></mat-progress-spinner>

      </div>
    </div>

  </div>

</div>
