<div *ngIf="mode === 'ADDRESSES'">
  <div class="flex justify-between w-full p-4 items-center">
    <h1 mat-dialog-title>User Settings</h1>
    <div mat-dialog-actions>
      <button mat-icon-button aria-label="Close Connect Wallet Dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div>
    <div mat-dialog-content>

      <div class="px-4 pb-4">
        <div *ngIf="loading" class="icon-container">

          <mat-progress-spinner mode="indeterminate" diameter="60"></mat-progress-spinner>

        </div>

        <div *ngIf="!loading">

          <app-slippage-tolerance></app-slippage-tolerance>

          <div>
            <button (click)="mode = 'PENDING_TXS'" class="pending-tx-btn flex items-center pl-0">
              Transactions ({{pendingTxCount}} Pending)
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>

          <!-- MayaChain -->
          <div *ngIf="mayaAddress" class="uni-input user-address" (click)="selectAddress(mayaAddress, 'MAYA')">
            <label>MAYA Address</label>

            <div class="flex items-center w-full py-3">
              <div class="asset-icon-container">
                <img class="asset-icon" src="assets/images/token-icons/cacao-logo.png" />
              </div>
              <span>{{mayaAddress.substring(0, 8) + '...' + mayaAddress.substring(mayaAddress.length - 4, mayaAddress.length)}}</span>
            </div>
          </div>

          <!-- Bitcoin -->
          <div *ngIf="bitcoinAddress" class="uni-input user-address" (click)="selectAddress(bitcoinAddress, 'BTC')">
            <label>Bitcoin Address</label>

            <div class="flex items-center w-full py-3">
              <div class="asset-icon-container">
                <img class="asset-icon" src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/assets/BTCB-1DE/logo.png" />
              </div>
              <span>{{bitcoinAddress.substring(0, 8) + '...' + bitcoinAddress.substring(bitcoinAddress.length - 4, bitcoinAddress.length)}}</span>
            </div>
          </div>

          <!-- Ethereum -->
          <div *ngIf="ethereumAddress" class="uni-input user-address" (click)="selectAddress(ethereumAddress, 'ETH')">
            <label>Ethereum Address</label>

            <div class="flex items-center w-full py-3">
              <div class="asset-icon-container">
                <img class="asset-icon" src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png" />
              </div>
              <span>{{ethereumAddress.substring(0, 8) + '...' + ethereumAddress.substring(ethereumAddress.length - 4, ethereumAddress.length)}}</span>
            </div>
          </div>

          <!-- Binance -->
          <div *ngIf="binanceAddress" class="uni-input user-address" (click)="selectAddress(binanceAddress, 'BNB')">
            <label>BNB Address</label>

            <div class="flex items-center w-full py-3">
              <div class="asset-icon-container">
                <img class="asset-icon" src="assets/images/token-icons/bnb.png" />
              </div>
              <span>{{binanceAddress.substring(0, 8 ) + '...' + binanceAddress.substring(binanceAddress.length - 4, binanceAddress.length)}}</span>
            </div>
          </div>

          <!-- Litecoin -->
          <div *ngIf="litecoinAddress" class="uni-input user-address" (click)="selectAddress(litecoinAddress, 'LTC')">
            <label>Litecoin Address</label>

            <div class="flex items-center w-full py-3">
              <div class="asset-icon-container">
                <img class="asset-icon" src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/litecoin/info/logo.png" />
              </div>
              <span>{{litecoinAddress.substring(0, 8) + '...' + litecoinAddress.substring(litecoinAddress.length - 4, litecoinAddress.length)}}</span>
            </div>
          </div>

          <!-- Bitcoin Cash -->
          <div *ngIf="bchAddress" class="uni-input user-address" (click)="selectAddress(bchAddress, 'BCH')">
            <label>Bitcoin Cash Address</label>

            <div class="flex items-center w-full py-3">
              <div class="asset-icon-container">
                <img class="asset-icon" src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/bitcoincash/info/logo.png" />
              </div>
              <span>{{bchAddress.substring(0, 8) + '...' + bchAddress.substring(bchAddress.length - 4, bchAddress.length)}}</span>
            </div>
          </div>

          <div class="flex justify-end">
            <button *ngIf="user?.type === 'keystore'" mat-flat-button (click)="mode = 'VIEW_PHRASE'">
              <mat-icon>visibility</mat-icon>
              View Phrase
            </button>
            <span class="w-2"></span>
            <button (click)="disconnect()" mat-flat-button color="primary">Disconnect</button>
          </div>

        </div>

      </div>

    </div>

  </div>
</div>

<app-pending-txs-modal *ngIf="mode === 'PENDING_TXS'" (back)="mode = 'ADDRESSES'"></app-pending-txs-modal>

<app-user-address
  *ngIf="mode === 'ADDRESS'"
  [chain]="selectedChain"
  [address]="selectedAddress"
  [pools]="pools"
  (back)="clearSelectedAddress()"
  (navigateToAsset)="selectAsset($event)"
  (navigateToAddToken)="mode = 'ADDRESS_ADD_TOKEN'">
</app-user-address>

<app-user-asset
  *ngIf="mode === 'ASSET'"
  [asset]="selectedAsset"
  [address]="selectedAddress"
  (back)="clearSelectedAsset()"
  (send)="mode = 'SEND'"
  (upgradeCacao)="mode = 'UPGRADE_CACAO'"
  (deposit)="mode = 'DEPOSIT'">
</app-user-asset>

<app-send-asset
  *ngIf="mode === 'SEND'"
  [asset]="selectedAsset"
  (confirmSend)="confirmSend($event)"
  (back)="mode = 'ASSET'">
</app-send-asset>

<app-confim-send
  *ngIf="mode === 'CONFIRM_SEND'"
  [amount]="amountToSend"
  [recipientAddress]="recipient"
  [memo]="memo"
  [asset]="selectedAsset"
  (back)="mode = 'SEND'"
  (transactionSuccessful)="transactionSuccessful()">
</app-confim-send>

<app-upgrade-cacao
  *ngIf="mode === 'UPGRADE_CACAO'"
  [asset]="selectedAsset"
  (confirmUpgrade)="confirmUpgradeCacao($event)"
  (back)="mode = 'ASSET'">
</app-upgrade-cacao>

<app-upgrade-cacao-confirm
  *ngIf="mode === 'CONFIRM_UPGRADE_CACAO'"
  [asset]="selectedAsset"
  [amount]="amountToSend"
  (back)="mode = 'UPGRADE_CACAO'"
  (transactionSuccessful)="transactionSuccessful()">
</app-upgrade-cacao-confirm>

<app-view-phrase
  *ngIf="mode === 'VIEW_PHRASE'"
  (back)="mode = 'ADDRESSES'">
</app-view-phrase>

<app-deposit
  *ngIf="mode === 'DEPOSIT'"
  [asset]="selectedAsset"
  (back)="mode = 'ASSET'">
</app-deposit>

<app-user-address-add-token
  *ngIf="mode === 'ADDRESS_ADD_TOKEN'"
  [chain]="selectedChain"
  [chainAddress]="selectedAddress"
  (back)="mode = 'ADDRESS'">
</app-user-address-add-token>
