<div class="background-container"></div>
<div class="noise-background"></div>

<div class="relative min-h-screen flex flex-col justify-between">

  <div>
    <app-header class="w-full"></app-header>

    <div class="relative">
      <div id="notifications" class="relative xl:absolute xl:top-2 xl:right-2 float-right py-4 px-8 w-full xl:w-96">

        <!-- Commenting out for now while the network is down -->
        <!-- <app-native-cacao-prompt *ngIf="user && (user?.type === 'keystore' || user?.type === 'XDEFI')" [nonNativeCacaoAssets]="nonNativeCacaoAssets"></app-native-cacao-prompt> -->

        <div *ngIf="chainBalanceErrors && chainBalanceErrors.length > 0" class="border-opacity-75 border rounded-lg text-yellow-500 border-yellow-500 px-6 py-4 mb-8">
          <div class="flex items-center">
            <mat-icon class="mr-4">warning</mat-icon>
            <small>
              There has been an error fetching some of your balances.
              Your funds are safe, you can check them on the respective explorer in the User Settings area.
              Please try again later.
            </small>
          </div>
        </div>
      </div>

      <main *ngIf="appLocked" class="flex flex-col justify-center items-center clear-both px-4 sm:px-0">
        <div class="container flex items-center justify-center">
          <div class="py-4">
            <mat-icon style="font-size: 48px; height: 48px; width: 48px;">construction</mat-icon>
          </div>
          <span>
            MayaChain is undergoing updates.<br />
            Please try again later.
          </span>
        </div>
      </main>

      <main *ngIf="!appLocked" [ngClass]="{'py-8 xl:py-32': notificationsExist(), 'py-32': !notificationsExist()}" class="flex flex-col justify-center items-center clear-both px-4 sm:px-0">
        <router-outlet></router-outlet>
      </main>

    </div>

  </div>

  <footer class="w-full p-8">
    <div class="footer-social flex justify-end">
      <a class="footer-logo footer-logo--telegram" href="https://t.me/mayachain_org" target="_blank"></a>
      <a class="footer-logo footer-logo--twitter" href="https://twitter.com/MayaProtocolEng" target="_blank"></a>
      <a class="footer-logo footer-logo--github" href="https://gitlab.com/mayachain/frontend/mayadex" target="_blank"></a>
    </div>
    <div class="footer-content">
      <span>This product is in beta. Do not deposit or swap large amounts of funds.</span>
      <div class="flex mayachain-indicator">
        <span class="mr-1">Powered by <a target="_blank" href="https://mayaprotocol.com/">Maya</a> </span>
        <app-last-block-indicator></app-last-block-indicator>
      </div>
    </div>
  </footer>

</div>



