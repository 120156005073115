<app-modal-section-header sectionTitle="Manual Memo" sectionTitleIcon="warning" [displayBackBtn]="true" (back)="back.emit()"></app-modal-section-header>

<div mat-dialog-content *ngIf="asset">

  <div>

    <div class="p-4">
      <div class="uni-input">
        <label>Memo</label>
        <textarea type="text" [ngModel]="memo" [disabled]="true"></textarea>
      </div>
    </div>

    <div class="memo-form" style="border-top: 1px solid #2c2f36; border-bottom: 1px solid #2c2f36">

      <div class="p-4">

        <div class="uni-input">
          <label>Action</label>
          <mat-select [ngModel]="action" (ngModelChange)="updateAction($event)">
            <mat-option value="SWAP">
              Swap
            </mat-option>
            <mat-option value="ADD">
              Add
            </mat-option>
            <mat-option value="WITHDRAW">
              Withdraw
            </mat-option>
            <mat-option value="BOND">
              Bond
            </mat-option>
            <mat-option value="UNBOND">
              Unbond
            </mat-option>
            <mat-option value="LEAVE">
              Leave
            </mat-option>
          </mat-select>
        </div>

        <div *ngIf="action === 'SWAP' || action === 'ADD' || action === 'WITHDRAW'" class="uni-input focus:outline-none focus:ring-0">
          <label>Asset</label>
          <input type="text" [ngModel]="memoAsset" (ngModelChange)="onMemoAssetChange($event)">
        </div>

        <div *ngIf="action === 'SWAP' || action === 'BOND' || action === 'UNBOND' || action === 'LEAVE' || action === 'ADD'">
          <div class="uni-input focus:outline-none focus:ring-0">
            <label>Address <span *ngIf="action === 'ADD'">(Optional)</span></label>
            <input type="text" [ngModel]="destinationAddress" (ngModelChange)="updateDestinationAddress($event)">
          </div>
        </div>

        <div class="uni-input focus:outline-none focus:ring-0" *ngIf="action === 'SWAP'">
          <label>Limit</label>
          <input type="text" [ngModel]="swapLimit" (ngModelChange)="updateSwapLimit($event)">
        </div>

        <div class="uni-input focus:outline-none focus:ring-0" *ngIf="action === 'UNBOND'">
          <label>Unbond Amount</label>
          <input type="number" [ngModel]="memoAmount" (ngModelChange)="updateUnbondAmount($event)">
        </div>

        <div class="uni-input focus:outline-none focus:ring-0" *ngIf="action === 'WITHDRAW'">

          <span>Amount</span>

          <span class="unstake-percent">{{withdrawAmount/100}}%</span>

          <mat-slider
            class="slider"
            [max]="10000"
            [min]="0"
            step="1"
            [tickInterval]="2500"
            [(ngModel)]="withdrawAmount">
          </mat-slider>

        </div>

      </div>

    </div>

    <div class="p-4">

      <app-asset-input
        *ngIf="action != 'WITHDRAW' && action != 'LEAVE' && action != 'UNBOND'"
        label="Input"
        [(selectedAsset)]="asset.asset"
        [balance]="asset.balance.amount().toNumber()"
        [(assetUnit)]="depositAmount"
        [disabledMarketSelect]="true"
        [selectableMarkets]="[asset]">
      </app-asset-input>

      <div class="flex justify-end items-center" style="height: 38px">
        <div>
          <button mat-flat-button color="accent" (click)="confirmSend()" [disabled]="(
            (action === 'ADD' || action === 'SWAP' || action === 'BOND') && (!depositAmount || depositAmount <= 0)
            || (action === 'UNBOND' && (!memoAmount || memoAmount < 0))
            )">
            <span>Next</span>
          </button>
        </div>
      </div>

    </div>

  </div>

</div>
