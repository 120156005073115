<div class="container">

  <app-modal-section-header sectionTitle="Secure Your Phrase" sectionTitleIcon="warning" [displayBackBtn]="false"></app-modal-section-header>

  <div class="dialog-content">
    <div mat-dialog-content>
      <app-phrase-words-list [phrase]="phrase"></app-phrase-words-list>
    </div>

    <div class="my-4">
      <small>This phrase is your access to your wallet and funds. Write it down and store it in a safe place. Do not share it with anyone. Asgardex never stores your keystore or phrase anywhere. This phrase is the only way to recover funds if your keystore is lost.</small>
    </div>

    <div class="flex justify-end">
      <button mat-flat-button color="primary" (click)="closeModal.emit()">I Understand</button>
    </div>

  </div>

</div>
