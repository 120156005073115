<app-modal-section-header
  [sectionTitle]="asset && asset.asset && asset.asset.ticker ? 'Send ' + asset.asset.ticker : 'Send -'"
  [displayBackBtn]="true"
  (back)="back.emit()">
</app-modal-section-header>

<div mat-dialog-content *ngIf="asset">

  <div class="p-4">
    <app-asset-input
      label="Input"
      [(selectedAsset)]="asset.asset"
      [(assetUnit)]="amount"
      [balance]="asset.balance.amount().toNumber()"
      [disabledAssetSymbol]="asset.asset.symbol"
      [disabledMarketSelect]="true"
      [selectableMarkets]="[asset]"
      txType="EXTERNAL">
    </app-asset-input>

    <div class="uni-input">
      <label>Recipient Address</label>
      <input [(ngModel)]="recipientAddress" name="recipient-address" placeholder="Enter Recipient {{asset.asset.ticker}} Address" autocomplete="off" />
    </div>

    <div *ngIf="asset.asset.chain === 'BNB'" class="uni-input">
      <label>Memo (Optional)</label>
      <input [(ngModel)]="memo" name="memo" placeholder="Leave blank if not required by recipient" autocomplete="off" />
    </div>

    <div class="flex justify-end items-center">
      <button
        (click)="confirmSend.next({amount: amount, recipientAddress: recipientAddress, memo: memo})"
        class="main-action-btn w-full"
        mat-flat-button
        color="primary"
        [disabled]="nextDisabled()">
        {{mainButtonText()}}
      </button>
    </div>

  </div>

</div>

