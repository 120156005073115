import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MayaConstants } from '../_classes/maya-constants';
import { PoolAddressDTO } from '../_classes/pool-address';
import { TransactionDTO } from '../_classes/transaction';
import { LastBlock } from '../_classes/last-block';
import { PoolDTO } from '../_classes/pool';
import { MemberDTO } from '../_classes/member';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NetworkSummary } from '../_classes/network';
import { LiquidityProvider } from '../_classes/liquidity-provider';

export interface ItzamnaResponse {
  [key: string]: number;
}

export interface MayanodeTx {
  observed_tx: {
    status: string;
  };
}

export interface MayanodeQueue {
  swap: number;
  outbound: number;
  internal: number;
}

@Injectable({
  providedIn: 'root',
})
export class LuumService {
  private v2BasePath: string;
  private _mayanodeBasePath: string;
  private _constants$: Observable<MayaConstants>;
  private _mimir$: Observable<ItzamnaResponse>;

  constructor(private http: HttpClient) {
    this.v2BasePath =
      environment.network === 'testnet'
        ? 'https://testnet.midgard.mayachain.info/v2'
        : 'https://stagenet.midgard.mayachain.info/v2';

    this._mayanodeBasePath =
      environment.network === 'testnet'
        ? 'https://testnet.mayanode.mayachain.info'
        : 'https://stagenet.mayanode.mayachain.info';

    // cached since constants are constant
    this._constants$ = this.http
      .get<MayaConstants>(`${this.v2BasePath}/mayachain/constants`)
      .pipe(shareReplay());
    this._mimir$ = this.http
      .get<ItzamnaResponse>(`${this._mayanodeBasePath}/mayachain/mimir`)
      .pipe(shareReplay());
  }
  /**
   * V2 Endpoints
   *
   */

  getConstants(): Observable<MayaConstants> {
    return this._constants$;
  }

  getLastBlock(): Observable<LastBlock[]> {
    return this.http.get<LastBlock[]>(`${this.v2BasePath}/mayachain/lastblock`);
  }

  getNetwork(): Observable<NetworkSummary> {
    return this.http.get<NetworkSummary>(`${this.v2BasePath}/network`);
  }

  getInboundAddresses(): Observable<PoolAddressDTO[]> {
    return this.http.get<PoolAddressDTO[]>(
      `${this.v2BasePath}/mayachain/inbound_addresses`
    );
  }

  getPools(): Observable<PoolDTO[]> {
    return this.http.get<PoolDTO[]>(`${this.v2BasePath}/pools`);
  }

  getPool(asset: string): Observable<PoolDTO> {
    return this.http.get<PoolDTO>(`${this.v2BasePath}/pool/${asset}`);
  }

  getMember(address: string): Observable<MemberDTO> {
    return this.http.get<MemberDTO>(`${this.v2BasePath}/member/${address}`);
  }

  getTransaction(txId: string): Observable<TransactionDTO> {
    const params = new HttpParams()
      .set('offset', '0')
      .set('limit', '1')
      .set('txid', txId);
    return this.http.get<TransactionDTO>(`${this.v2BasePath}/actions`, {
      params,
    });
  }

  getMayanodeTransaction(hash: string): Observable<MayanodeTx> {
    return this.http.get<MayanodeTx>(
      `${this._mayanodeBasePath}/mayachain/tx/${hash}`
    );
  }

  getQueue(): Observable<MayanodeQueue> {
    return this.http.get<MayanodeQueue>(`${this.v2BasePath}/mayachain/queue`);
  }

  getMimir(): Observable<ItzamnaResponse> {
    return this._mimir$;
  }

  getMayachainLiquidityProviders(
    asset: string
  ): Observable<LiquidityProvider[]> {
    return this.http.get<LiquidityProvider[]>(
      `${this._mayanodeBasePath}/mayachain/pool/${asset}/liquidity_providers`
    );
  }
}
