<div class="container">

  <app-modal-section-header sectionTitle="Transactions" [displayBackBtn]="true" [displayCloseBtn]="true" (back)="back.emit()" (closeModal)="close()"></app-modal-section-header>

  <div class="dialog-content">
    <div mat-dialog-content>

      <div>

        <ul *ngIf="txs && txs.length > 0">
          <li *ngFor="let tx of txs">

            <div class="uni-input hover:border-transparent">

              <label class="flex justify-between w-full">
                <span>{{tx.ticker}}</span>
                <span>{{tx.action}}</span>
              </label>

              <div class="flex items-center justify-between w-full">

                <div class="flex flex-col">
                  <span>{{tx.hash.substring(0, 4) + '...' + tx.hash.substring(tx.hash.length - 4, tx.hash.length)}}</span>
                  <div class="flex">
                    <a class="flex align-center text-sm mr-2 text-green-300" [href]="explorerPath(tx)" target="_blank">
                      <span>{{tx.chain}} Explorer</span>
                      <mat-icon style="transform: scale(.5);">open_in_new</mat-icon>
                    </a>
                    <a class="flex align-center text-sm text-green-300" *ngIf="tx.isMayachainTx && tx.chain !== 'MAYA'" href="{{mayachainExplorerUrl}}/{{tx.hash}}" target="_blank">
                      <span>MAYA Explorer</span>
                      <mat-icon style="transform: scale(.5);">open_in_new</mat-icon>
                    </a>
                  </div>
                </div>

                <button class="flex items-center justify-center" matSuffix mat-icon-button>
                  <mat-icon *ngIf="tx.status === 'COMPLETE'">check_circle</mat-icon>
                  <mat-icon matTooltip="Transaction Refunded" *ngIf="tx.status === 'REFUNDED'">undo</mat-icon>
                  <mat-progress-spinner *ngIf="tx.status === 'PENDING'" diameter="16" color="primary" mode="indeterminate"></mat-progress-spinner>
                </button>

              </div>

              <span *ngIf="tx.action === 'Refund'" style="font-size: 0.6rem; font-style: italic">*Something went wrong submitting your transaction on the network. Your transaction has been refunded.</span>

            </div>

          </li>
        </ul>

        <div *ngIf="!txs || (txs && txs.length < 1)">
          No transactions found
        </div>

      </div>

    </div>

  </div>

</div>
