<button
  *ngIf="user"
  mat-flat-button
  class="border border-gray-900 border-opacity-75"
  (click)="openUserSettings()"
>
  <span *ngIf="pendingTxCount <= 0">{{user.wallet.substring(0, 6)}}...{{user.wallet.substring(user.wallet.length - 4, user.wallet.length)}}</span>
  <div *ngIf="pendingTxCount > 0" class="flex items-center">
    <span class="mr-3">{{pendingTxCount}} Pending</span>
    <mat-progress-spinner class="light-spinner" mode="indeterminate" diameter="16" color="accent"></mat-progress-spinner>
  </div>
</button>
