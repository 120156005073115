<app-modal-section-header
  [sectionTitle]="chain ? 'Add ' + chain + ' Token' : 'Add ... Token'"
  [displayBackBtn]="true"
  (back)="back.emit()">
</app-modal-section-header>

<div mat-dialog-content>

  <div *ngIf="!error" class="p-4">

    <div class="uni-input">
      <label>Token Contract Address</label>
      <input [(ngModel)]="tokenAddress" name="custom-token-address" placeholder="Enter {{chain}} Token Address" />
    </div>

    <div class="flex justify-end items-center" style="height: 38px">
      <div>
        <button *ngIf="!loading" mat-flat-button color="accent" [disabled]="tokenAddress.length < 40 || tokenAddress.length > 42 || !ethClient || !chainAddress || !chain" (click)="addToken()">
          <span>Add Token</span>
        </button>
        <mat-progress-spinner mode="indeterminate" diameter="30" *ngIf="loading"></mat-progress-spinner>
      </div>
    </div>

  </div>

  <div *ngIf="error">
    An error occurred <br />
    {{error}}
  </div>

</div>
