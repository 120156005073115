import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-funds-warning-dialog',
  templateUrl: './funds-warning-dialog.component.html',
  styleUrls: ['./funds-warning-dialog.component.scss']
})
export class FundsWarningDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
