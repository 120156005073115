<div *ngIf="txState == 'PENDING_CONFIRMATION' && asset">

  <app-modal-section-header sectionTitle="Confirm Upgrade" [displayBackBtn]="true" (back)="back.emit()"></app-modal-section-header>

  <div class="p-4 confirm-modal-head">

    <div class="flex justify-between transaction-detail">

      <div class="flex items-center">
        <div style="margin-right: 12px;" class="asset-icon-container flex items-center">
          <img *ngIf="asset.asset && asset.asset.iconPath" [src]="asset.asset.iconPath" appImgFallback />
          <mat-icon *ngIf="!asset.asset.iconPath">help_outline</mat-icon>
        </div>
        <span style="margin-right: 8px; font-size: 20px">{{amount}}</span>
      </div>

      <span>{{asset.asset.ticker}}</span>

    </div>

    <small>
      This CACAO is currently a {{asset.asset.chain}} token. <br />
      By clicking the button below, you will be upgrading it off of the {{asset.asset.chain}} chain to native CACAO. Your native CACAO will show up in your CACAO wallet in user settings.
    </small>

    <div class="flex justify-between mt-4 mb-2">
      <span class="tx-detail-label">Fees</span>
      <span class="text-sm" *ngIf="networkFee && asset">{{networkFee | number:"0.0-4"}} {{asset.asset.chain}}</span>
      <span class="text-sm" *ngIf="!networkFee || !asset">-</span>
    </div>

    <button [disabled]="insufficientChainBalance" (click)="submitTransaction()" mat-flat-button color="accent" class="main-action-btn w-full">
      <span *ngIf="!insufficientChainBalance">Upgrade CACAO</span>
      <span *ngIf="insufficientChainBalance">Insufficient {{asset.asset.chain}} Balance</span>
    </button>

  </div>

</div>

<app-transaction-processing-modal
  *ngIf="txState === 'SUBMITTING' && asset"
  transactionDetail="Sending {{asset.asset.ticker}}">
</app-transaction-processing-modal>

<div class="flex flex-col items-center py-8 text-sm" *ngIf="txState === 'ERROR'">
  <mat-icon>warning</mat-icon>
  There was an error processing the CACAO upgrade.
</div>
