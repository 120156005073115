<app-modal-section-header sectionTitle="Upgrade to Native CACAO" [displayBackBtn]="true" (back)="back.next()"></app-modal-section-header>

<div mat-dialog-content *ngIf="asset">

  <div class="p-4">
    <app-asset-input
      label="Input"
      [(selectedAsset)]="asset.asset"
      [(assetUnit)]="amount"
      [balance]="asset.balance.amount().toNumber()"
      [disabledAssetSymbol]="asset.asset.symbol"
      [disabledMarketSelect]="true">
    </app-asset-input>

    <small>
      This CACAO is currently a {{asset.asset.chain}} token. <br />
      You can upgrade it to Native CACAO, which will show up in your CACAO wallet in user settings.
    </small>

    <div class="flex justify-end items-center mt-4" style="height: 38px;">
      <div>
        <button
          mat-flat-button
          color="accent"
          [disabled]="!amountSpendable"
          (click)="confirmUpgrade.next({amount: amount})">
            <span>Next</span>
        </button>

      </div>
    </div>

  </div>

</div>
