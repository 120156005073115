<app-modal-section-header [displayBackBtn]="true" (back)="back.emit()"></app-modal-section-header>

<div class="p-4 flex flex-col">

  <div class="flex flex-col justify-center items-center">
    <div class="mb-4" style="width: 40px;">
      <img *ngIf="asset && asset.asset && asset.asset.iconPath" class="asset-icon" [src]="asset && asset.asset && asset.asset.iconPath" appImgFallback style="max-width: 100%;" />
    </div>

    <div *ngIf="address" class="flex flex-col justify-center">
      <qrcode class="flex justify-center mb-2" [qrdata]="address" [width]="256" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
      <span style="font-size: 0.8rem">{{address}}</span>
    </div>

    <span *ngIf="asset" class="asset-balance">{{asset.balance.amount().toNumber()}} {{asset.asset.ticker}}</span>
    <div style="height: 17px">
      <span class="usd-value" *ngIf="usdValue">approx ${{usdValue | number:"0.0-2"}}</span>
    </div>

  </div>

  <ul class="actions flex justify-center">
    <li class="action flex flex-col">
      <button mat-mini-fab color="primary" aria-label="Send" (click)="send.emit()">
        <mat-icon>upgrade</mat-icon>
      </button>
      Send
    </li>

    <li class="action flex flex-col items-center">
      <button mat-mini-fab color="primary" aria-label="Copy" (click)="copyToClipboard()">
        <mat-icon>content_copy</mat-icon>
      </button>
      Copy
    </li>

    <!-- NATIVE CACAO DEPOSIT -->
    <li *ngIf="asset && asset.asset.chain === 'MAYA' && asset.asset.ticker === 'CACAO'" class="action flex flex-col items-center">
      <button mat-mini-fab color="primary" aria-label="Copy" (click)="deposit.emit()">
        <mat-icon>flash_on</mat-icon>
      </button>
      Deposit
    </li>

    <!-- UPGRADE BNB/ETH CACAO TO NATIVE CACAO -->
    <li *ngIf="asset && (asset.asset.chain === 'BNB' || asset.asset.chain === 'ETH') && asset.asset.ticker === 'CACAO'" class="action flex flex-col items-center">
      <button mat-mini-fab color="primary" aria-label="Copy" (click)="upgradeCacao.emit()">
        <mat-icon>upgrade</mat-icon>
      </button>
      Native CACAO
    </li>

  </ul>

  <app-testnet-warning *ngIf="asset && asset.asset && asset.asset.chain" [chain]="asset.asset.chain"></app-testnet-warning>

</div>

<!-- <div mat-dialog-content>
  <p>txs here</p>
</div> -->
