import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpgradeCacaoComponent } from './upgrade-cacao.component';
import { MatIconModule } from '@angular/material/icon';
import { AssetInputModule } from '../asset-input/asset-input.module';
import { MatButtonModule } from '@angular/material/button';
import { ModalSectionHeaderModule } from '../modal-section-header/modal-section-header.module';

@NgModule({
  declarations: [UpgradeCacaoComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    AssetInputModule,
    ModalSectionHeaderModule,
  ],
  exports: [UpgradeCacaoComponent],
})
export class UpgradeCacaoModule {}
