<div *ngIf="mode === 'SELECT_ASSET'">

  <app-modal-section-header sectionTitle="Non-native CACAO Assets" [displayCloseBtn]="true" (closeModal)="dialogRef.close()"></app-modal-section-header>

  <div mat-dialog-content>
    <app-assets-list [assetListItems]="assets" [loading]="loading" [disabledAssetSymbol]="''" (selectAsset)="selectAsset($event)"></app-assets-list>
  </div>

</div>

<app-upgrade-cacao
  *ngIf="mode === 'UPGRADE_ASSET'"
  [asset]="selectedAsset"
  (confirmUpgrade)="confirmUpgradeCacao($event)"
  (back)="mode = 'SELECT_ASSET'">
</app-upgrade-cacao>

<app-upgrade-cacao-confirm
  *ngIf="mode === 'CONFIRM'"
  [asset]="selectedAsset"
  [amount]="amountToSend"
  (back)="mode = 'UPGRADE_ASSET'"
  (transactionSuccessful)="transactionSuccessful($event)">
</app-upgrade-cacao-confirm>

<app-transaction-success-modal
  *ngIf="mode === 'SUCCESS'"
  [externalTx]="true"
  [chain]="selectedAsset.asset.chain"
  [hash]="successfulTxHash"
  (closeDialog)="dialogRef.close()">
</app-transaction-success-modal>
