<div *ngIf="txState == 'PENDING_CONFIRMATION' && asset">

  <app-modal-section-header sectionTitle="Confirm Send" [displayBackBtn]="true" (back)="back.emit()"></app-modal-section-header>

  <div class="p-4 confirm-modal-head">

    <div class="flex justify-between transaction-detail clear-both">

      <div class="flex items-center">
        <div style="margin-right: 12px;" class="asset-icon-container flex items-center">
          <img *ngIf="asset.asset && asset.asset.iconPath" [src]="asset.asset.iconPath" appImgFallback />
          <mat-icon *ngIf="!asset.asset.iconPath">help_outline</mat-icon>
        </div>
        <span style="margin-right: 8px; font-size: 20px">{{amount}}</span>
      </div>

      <span>{{asset.asset.ticker}}</span>

    </div>

    <div class="flex">
      <mat-icon style="margin-left: 4px; margin-bottom: 12px;" class="to-btn-icon disabled text-sm">arrow_downward</mat-icon>
    </div>

    <div class="uni-input">
      <label>Recipient Address</label>
      <textarea [(ngModel)]="recipientAddress" name="recipient-address" [disabled]="true"></textarea>
    </div>

    <div *ngIf="asset.asset.chain === 'BNB'" class="uni-input">
      <label>Memo (Optional)</label>
      <textarea [(ngModel)]="memo" name="memo" [disabled]="true"></textarea>
    </div>

    <button (click)="submitTransaction()" mat-flat-button color="primary" class="main-action-btn w-full" [disabled]="insufficientChainBalance">
      <span *ngIf="insufficientChainBalance">Insufficient {{asset.asset.chain}}</span>
      <span *ngIf="!insufficientChainBalance">Send {{asset.asset.ticker}}</span>
    </button>

  </div>

</div>

<app-transaction-processing-modal
  *ngIf="txState === 'SUBMITTING' && asset"
  transactionDetail="Sending {{asset.asset.ticker}}">
</app-transaction-processing-modal>

<div *ngIf="txState === 'ERROR'" class="flex flex-col items-center py-8 text-sm">
  <mat-icon>warning</mat-icon>
  There was an error sending.<br />
  {{error}}
</div>

