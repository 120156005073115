<div class="container">

  <app-modal-section-header sectionTitle="Keystore Found"></app-modal-section-header>

  <div class="dialog-content">
    <div mat-dialog-content>

      <button style="cursor: auto;" class="uni-input uni-input--file" [disabled]="true">
        <span>Reconnect from previous session</span>
        <mat-icon [ngStyle]="{'color':'green'}">check</mat-icon>
      </button>

      <div class="uni-input">
        <label>Password</label>
        <input
          [(ngModel)]="keystorePassword"
          name="keystore-password"
          placeholder="Please Enter Password"
          type="password"
          (keyup.enter)="initUnlock()"
          class="focus:outline-none focus:ring-0" />
      </div>

    </div>

    <div class="flex justify-between items-center" style="height: 38px">
      <div>
        <span *ngIf="keystoreError" style="font-size: 0.8rem; color: #2172e5">Keystore Login Incorrect</span>
      </div>
      <div>
        <button class="mb-2" *ngIf="!keystoreConnecting" mat-flat-button (click)="forgetKeystore()" [disabled]="keystoreConnecting">
          <span>Forget Keystore</span>
        </button>
        <button *ngIf="!keystoreConnecting" mat-flat-button color="accent" (click)="initUnlock()" [disabled]="keystoreConnecting">
          <span>Unlock</span>
        </button>
        <mat-progress-spinner mode="indeterminate" diameter="30" *ngIf="keystoreConnecting"></mat-progress-spinner>

      </div>
    </div>

  </div>

</div>
